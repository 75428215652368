@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");

body {
  background: whitesmoke;
  color: black;
  margin: 0;
  padding: 0;
  font-family: "Fredoka", sans-serif;
}

@font-face {
  font-family: diagramm;
  src: local("diagramm"), url("./fonts/diagramm/regular.ttf") format("truetype");
  font-weight: black;
}
@font-face {
  font-family: gangster;
  src: local("gangster"), url("./fonts/gangster/Regular.otf") format("truetype");
}
@font-face {
  font-family: extended;
  src: local("extended"), url("./fonts/extend/bold.ttf") format("truetype");
}

.serif {
  font-family: "stay winter", sans-serif;
}

.color {
  color: #75224d;
}
.bg {
  background: #75224d;
}
.bold {
  font-family: "Fredoka", sans-serif;
}
.signature {
  font-family: "Creamy Corn", sans-serif;
}

.head {
  font-family: "Zin Sans", sans-serif;
  font-family: "Zin Sans Condensed", sans-serif;
  font-family: "Zin Sans Extended", sans-serif;
}

.clip {
  clip-path: circle(0% at 50% 100%);
}
.mainClip:hover .clip {
  clip-path: circle(111.8% at 50% 100%);
}
.swiper-wrapper {
  transition-timing-function: linear;
}
